<template>
  <div>
    <div class="manager_nav_logo" style="margin:10px">
      <a @click="goHome" target="_blank">
        <img src="../../assets/image/member_login_logo.png" alt />
      </a>
    </div>
    <div class="buy_check_container">
      <div v-loading="loading" element-loading-text="支付結果請求中" class="payForIcon">
        <div v-if="payForDetail.payForStatus">
          <div class="iconCont success">
            <i class="el-icon-success"></i>
          </div>
          <span>支付成功</span>
        </div>
        <div v-else>
          <div class="iconCont error">
            <i class="el-icon-error"></i>
          </div>
          <span>支付失敗</span>
        </div>
      </div>
      <div class="payFor_Detail">
        <div style="margin-top:0">
          <span>購買內容：</span>
          <span>{{payForDetail.shelfName}}</span>
        </div>
        <div>
          <span>原價金額：</span>
          <span>{{payForDetail.originalPrice}}</span>
        </div>
        <div v-if="payForDetail.preferentialCash">
          <span>優惠券金額:</span>
          <span>{{payForDetail.preferentialCash }}</span>
        </div>
        <div v-if="payForDetail.preferentialCash">
          <span>優惠券數量:</span>
          <span>{{payForDetail.couponNumber }}</span>
        </div>
        <div>
          <span>折扣總金額:</span>
          <span>{{payForDetail.totalDiscountCash}}</span>
        </div>
        <div>
          <span>支付金额：</span>
          <span>{{payForDetail.payForCash}}</span>
        </div>
        <div>
          <span>充值點數：</span>
          <span>{{payForDetail.rechargePoint}}</span>
        </div>
        <div>
          <span>購買支付狀態：</span>
          <span>{{payForDetail.payForStatusRemark}}</span>
        </div>
        <div>
          <span>購買支付明細：</span>
          <span>
            <el-button type="small" @click="openBaseInfor(1)">查看</el-button>
          </span>
        </div>
      </div>
    </div>
    <div class="pup_information" v-if="isShowDetail">
      <div class="pup_manager_bg" @click="openBaseInfor(0)"></div>
      <div class="pup_manager_window">
        <div class="pup_manager_container">
          <div class="pup_information_text" v-for="(query,i) in titleQuery" :key="i">
            {{query}}：
            <span>{{payQueryDetail[i]}}</span>
          </div>
        </div>
        <img
          src="../../assets/image/close_icon.png"
          @click="openBaseInfor(0)"
          class="pup_manager_close"
          alt
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getHsdrechargeOrder } from "../../api/api";
import { mapState } from "vuex";
export default {
  data() {
    return {
      queryInterVal: 0,
      payForDetail: {},
      payQueryDetail: {},
      isShowDetail: 0,
      loading: true,
      rechargeOrderId: "",
      titleQuery: {
        MerID: "商店代號",
        MerMemID: "賣方會員編號",
        MerName: "商店名稱",
        BuyerMemID: "買方會員編號",
        BuyerName: "買方會員名稱",
        BuyerAccNo: "買方會員電子帳戶帳號",
        BuyerEmail: "買方會員信箱",
        TradeNo: "交易序號",
        MerchantOrderNo: "商店訂單編號",
        Amt: "訂單金額",
        PaymentType: "支付方式",
        PaymentStatus: "支付狀態",
        AmtStatus: "價金狀態",
        CreateDT: "交易建立時間",
        PayDT: "支付時間",
        FundDT: "預計撥款日",
        RespondCode: "金融機構回應碼",
        Auth: "授權碼",
        ECI: "ECI 值",
        CloseStatus: "請款狀態",
        CloseAmt: "請款金額",
        BackStatus: "退款狀態",
        BackBalance: "可退款餘額",
        Inst: "分期-期別",
        InstFirst: "分期-首期金",
        InstEach: "分期-每期金額"
      }
    };
  },
  created() {},
  methods: {
    goHome() {
      // this.$router.push("/index");
    },
    async getRecharge() {
      const res = await getHsdrechargeOrder({
        rechargeOrderId: this.rechargeOrderId
      });
      if (res.success == 1) {
        this.payForDetail = res.data;
        if (res.data.queryOrderStatus == "SUCCESS") {
          clearInterval(this.queryInterVal);
          this.queryInterVal = null;
          this.loading = false;
        }
        if ((res.data.tradeOrderInfo.success = "SUCCESS")) {
          this.payQueryDetail = res.data.tradeOrderInfo.Result;
        }
      }
    },
    //打開明細彈窗
    openBaseInfor(id) {
      //   console.log(id);
      this.isShowDetail = id;
    }
  },
  mounted() {
    this.rechargeOrderId = window.localStorage.getItem("queryReachargeOrder");
    // console.log(this.rechargeOrderId)
    if (this.rechargeOrderId) {
      this.getRecharge();
      this.queryInterVal = setInterval(() => this.getRecharge(), 3000);
    }
  },
  destroyed() {
    this.queryInterVal = null;
  }
};
</script>
<style scoped>
@import "../../assets/css/manager_center.css";
.payForIcon {
  width: 200px;
  margin: 50px auto;
  text-align: center;
  font-size: 18px;
  color: #565656;
  font-weight: 600;
}
.payFor_Detail {
  width: auto;
  padding: 25px 100px;
  background: #f3f3f3;
  margin: 0 auto 40px;
}
.payFor_Detail div {
  margin-top: 20px;
}
.payFor_Detail div span:first-child {
  display: inline-block;
  width: 140px;
  margin-right: 12px;
}
.iconCont {
  font-weight: normal;
  font-size: 50px;
  margin-bottom: 10px;
}
.success {
  color: #67c23a;
}
.error {
  color: #f56c6c;
}
</style>